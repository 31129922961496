import {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useContext,
  useEffect,
  useState
} from 'react';
import { ErrorMessage } from '../components/ErrorMessage/ErrorMessage';
import { VesselAssetNumberAndName } from '../components/VesselSelectionDropdown/VesselSelectionDropdownConfiguration';
import { WELCOME_PAGE_PREFS_STORAGE_KEY } from '../utils/localStorageKeys';
import { DETAILS_PAGE_URL, HOME_PAGE_URL } from '../utils/routes';
import { useLocalStorageBackedState } from '../utils/useLocalStorageBackedState';
import { useOktaAuth } from './OktaContext';
import { SelectedVesselsContext } from './SelectedVesselsContext';
import { getCookie } from '../utils/storageUtils';

export const createUrlForVesselCalendar = (
  selectedVessels: VesselAssetNumberAndName[]
) => {
  let url = HOME_PAGE_URL;
  if (selectedVessels.length > 0) {
    const firstVessel = selectedVessels[0];
    url = `${DETAILS_PAGE_URL}/${firstVessel.assetNumber}/?from=/?summary=${firstVessel.assetNumber}`;
  }
  return url;
};

export interface DefaultRedirectContextState {
  /**
   * Default URL used in mast head
   */
  mastHeadURL: string;
  /**
   * Redirect URL used at login
   */
  redirectURL: string;
  /**
   * Local storage backed values that are
   * exposed in the context provider
   */
  welcomePagePrefs: WelcomePagePreferences;
  setWelcomePagePrefs: Dispatch<SetStateAction<WelcomePagePreferences>>;
}

export const DefaultRedirectContext =
  createContext<DefaultRedirectContextState>({} as DefaultRedirectContextState);

interface DefaultRedirectProviderProps {
  children: ReactNode;
  mastHeadURL: string;
  setMastHeadURL: Dispatch<SetStateAction<string>>;
}

export interface WelcomePagePreferences {
  showWelcomePageOnStartup: boolean;
}

export const DefaultRedirectProvider = ({
  children,
  mastHeadURL,
  setMastHeadURL
}: DefaultRedirectProviderProps) => {
  const { authState } = useOktaAuth();
  const [redirectURL, setRedirectURL] = useState<string>('');
  const [initialized, setInitialized] = useState<boolean>(false);
  const { selectedVessels } = useContext(SelectedVesselsContext);

  const [welcomePagePrefs, setWelcomePagePrefs] =
    useLocalStorageBackedState<WelcomePagePreferences>({
      key: WELCOME_PAGE_PREFS_STORAGE_KEY,
      initialValue: { showWelcomePageOnStartup: true },
      persist: true
    });

  let content = children;
  /**
   * If we haven't initialized this component yet, and we are
   * already authenticated, then set the redirect urls.
   */
  let mastheadRedirect = '';
  let defaultRedirect = '';
  if (!initialized && !!authState?.accessToken?.accessToken) {
    if (selectedVessels && selectedVessels.length > 0) {
      mastheadRedirect = createUrlForVesselCalendar(selectedVessels);
      if (
        !welcomePagePrefs.showWelcomePageOnStartup ||
        !!getCookie('welcome_was_shown')
      ) {
        defaultRedirect = mastheadRedirect;
      } else {
        defaultRedirect = HOME_PAGE_URL;
      }
    } else {
      defaultRedirect = HOME_PAGE_URL;
      mastheadRedirect = HOME_PAGE_URL;
      content = (
        <div className="h-screen flex flex-col items-center justify-center">
          <ErrorMessage />
        </div>
      );
    }
  }

  useEffect(() => {
    if (
      !initialized &&
      !!authState?.accessToken?.accessToken &&
      !!mastheadRedirect &&
      !!defaultRedirect
    ) {
      setMastHeadURL(mastheadRedirect);
      setRedirectURL(defaultRedirect);
      setInitialized(true);
    }
  }, [
    authState?.accessToken?.accessToken,
    defaultRedirect,
    initialized,
    mastheadRedirect,
    setMastHeadURL
  ]);

  return (
    <DefaultRedirectContext.Provider
      value={{
        mastHeadURL,
        redirectURL,
        welcomePagePrefs,
        setWelcomePagePrefs
      }}
    >
      {content}
    </DefaultRedirectContext.Provider>
  );
};
