import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useState
} from 'react';
import axios from 'axios';
import { FilterContext } from './FilterContext';
import { FilterBy, TextFilterBy } from '../utils/useFilterState';
import { useOktaAuth } from './OktaContext';
import { useInterval } from '../utils/useInterval';
import { ErrorMessage } from '../components/ErrorMessage/ErrorMessage';
import { Loading } from '../components/Loading/Loading';
import { Vessel } from '../models/Vessel';
import { getVesselUrl } from '../utils/vesselUtils';
import { intersection, isNil, sortBy } from 'lodash';
import { Team } from '../models/Team';

export interface APIResponse {
  data: Vessel[];
}

export interface VesselContextState {
  /**
   * All the vessels from the API
   */
  vessels: Vessel[];
  /**
   * Vessels based on the filters
   */
  filteredVessels: Vessel[];
  /**
   * Set to true when API is fetching
   * vessel data
   */
  isFetching: boolean;
  /**
   * Expose the call to refresh vessels outside
   * of the context
   */
  refreshVessels: () => Promise<void>;
}

export const VesselContext = createContext<VesselContextState>(
  {} as VesselContextState
);

interface Props {
  children: ReactNode;
}

const filterVesselsByGrouping = (
  theseVessels: Vessel[],
  toggleFilters: Partial<Record<FilterBy, Set<string>>>
) => {
  const filtered = theseVessels.filter((vessel) => {
    return Object.keys(toggleFilters).every((key) => {
      const k = key as FilterBy;
      const value = toggleFilters[k];
      if (toggleFilters[k]?.size !== 0 && value) {
        const filtersValueArray = Array.from(value);
        let returnValue;
        if (k === 'Team')
          returnValue = filterTeams(vessel[k], filtersValueArray);
        else if (k === 'Active')
          returnValue = filterActive(vessel[k], filtersValueArray);
        else returnValue = filtersValueArray.includes(vessel[k] ?? '');
        return returnValue;
      }

      return true;
    });
  });

  return filtered;
};

const filterTeams = (team: Team | undefined, filtersValueArray: string[]) => {
  const teamMemberNames = team?.TeamMembers.map(({ Name }) => Name);
  const commonElements = intersection(teamMemberNames, filtersValueArray);
  return !!commonElements.length;
};

const filterActive = (
  active: boolean | undefined,
  filtersValueArray: string[]
) => {
  const isTruthy = active || isNil(active);
  const isFalse = active === false;
  return (
    (isTruthy && filtersValueArray.includes('true')) ||
    (isFalse && filtersValueArray.includes('false'))
  );
};

const filterVesselsByFieldContains = (
  theseVessels: Vessel[],
  textFilters: Partial<Record<TextFilterBy, string>>
) => {
  const filtered = theseVessels.filter((vessel) => {
    return Object.keys(textFilters).every((key) => {
      const fieldName = key as TextFilterBy;
      const filterValue = textFilters[fieldName];

      if (filterValue && filterValue.length > 0) {
        const { [fieldName]: filterField } = vessel;

        return filterField
          ?.toLowerCase()
          .includes(filterValue?.trim().toLowerCase());
      }

      return true;
    });
  });

  return filtered;
};

export function VesselProvider({ children }: Props) {
  const [vessels, setVessels] = useState<Vessel[]>([]);
  const [isFetching, setIsFetching] = useState<boolean>(false);
  const [hasSuccessfulFetch, setHasSuccessfulFetch] = useState<boolean>(false);
  // Copy when updating the data
  const [filteredVessels, setFilteredVessels] = useState<Vessel[]>([]);
  const { activeToggleFilters, activeTextFilters } = useContext(FilterContext);
  const { authState } = useOktaAuth();

  const callApi = useCallback(async () => {
    setIsFetching(true);
    try {
      // Only Make API Request if the user is logged in
      if (authState?.accessToken?.accessToken) {
        const { data } = await axios.get<APIResponse>(getVesselUrl(), {
          headers: {
            Authorization: `Bearer ${authState.accessToken.accessToken}`
          }
        });
        const orderedData = sortBy(data.data, [
          (v) => v.VesselName.toLowerCase()
        ]);
        setVessels(orderedData);
        setFilteredVessels(orderedData);
        setHasSuccessfulFetch(true);
      }
    } catch (error) {
      // TODO: Error handling
      console.error(error);
    } finally {
      setIsFetching(false);
    }
  }, [authState?.accessToken, setVessels]);

  useEffect(() => {
    callApi();
  }, [authState, callApi]);

  // Poll API every 30s, The DB behind the API is updated every 60s
  useInterval(() => callApi(), 30000);

  /**
   * Expose the call to refresh vessels outside
   * of the context
   */
  const refreshVessels = useCallback(async () => {
    callApi();
  }, [callApi]);

  /**
   * Performs client-side filtering of vessel data.  filterVesselsByGrouping
   * will filter vessels based on the checkboxes applied in the sidebar, and
   * filterVesselsByFieldContains will filter vessels by any text fields
   * defined using contains comparison logic.
   */
  const filterData = useCallback(() => {
    const filtered = filterVesselsByGrouping(vessels, activeToggleFilters);

    const extraFilteredVessels = filterVesselsByFieldContains(
      filtered,
      activeTextFilters
    );

    setFilteredVessels(extraFilteredVessels);
  }, [activeToggleFilters, activeTextFilters, vessels]);

  useEffect(() => {
    filterData();
  }, [filterData]);

  let content = children;

  // If we have no successful fetches, and we are already authed, we are either fetching now, or there was an error fetching
  if (!hasSuccessfulFetch && !!authState?.accessToken?.accessToken) {
    if (isFetching) {
      content = (
        <div className="h-screen flex flex-col items-center justify-center">
          <Loading />
        </div>
      );
    } else {
      content = (
        <div className="h-screen flex flex-col items-center justify-center">
          <ErrorMessage />
        </div>
      );
    }
  }

  return (
    <VesselContext.Provider
      value={{
        vessels,
        filteredVessels,
        isFetching,
        refreshVessels
      }}
    >
      {content}
    </VesselContext.Provider>
  );
}
