import {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useContext,
  useState
} from 'react';
import { ErrorMessage } from '../components/ErrorMessage/ErrorMessage';
import { Loading } from '../components/Loading/Loading';
import { VesselAssetNumberAndName } from '../components/VesselSelectionDropdown/VesselSelectionDropdownConfiguration';
import { VESSEL_MULTISELECTOR_PREFERENCES_STORAGE_KEY } from '../utils/localStorageKeys';
import { useLocalStorageBackedState } from '../utils/useLocalStorageBackedState';
import { useOktaAuth } from './OktaContext';
import { VesselContext } from './VesselContext';

export interface SelectedVesselsContextState {
  selectedVessels: VesselAssetNumberAndName[];
  setSelectedVessels: Dispatch<SetStateAction<VesselAssetNumberAndName[]>>;
}

export const SelectedVesselsContext =
  createContext<SelectedVesselsContextState>({} as SelectedVesselsContextState);

interface SelectedVesselsProviderProps {
  children: ReactNode;
}

export const SelectedVesselsProvider = ({
  children
}: SelectedVesselsProviderProps) => {
  const { authState } = useOktaAuth();
  const [selectedVessels, setSelectedVessels] = useLocalStorageBackedState<
    VesselAssetNumberAndName[]
  >({
    key: VESSEL_MULTISELECTOR_PREFERENCES_STORAGE_KEY,
    initialValue: [],
    persist: true
  });

  const { vessels, isFetching } = useContext(VesselContext);
  const [initialized, setInitialized] = useState<boolean>(false);

  let content = children;

  /**
   * If we haven't initialized this component yet for selected
   * vessels, and we are already authenticated, then we either
   * reuse the selected vessels in local storage, or attempt
   * to set them from the vessels from context.
   */
  if (!initialized && !!authState?.accessToken?.accessToken) {
    if (selectedVessels.length > 0) {
      setInitialized(true);
    } else if (isFetching) {
      content = (
        <div className="h-screen flex flex-col items-center justify-center">
          <Loading />
        </div>
      );
    } else if (!isFetching && vessels.length > 0) {
      setSelectedVessels([
        { assetNumber: vessels[0].AssetNumber, name: vessels[0].VesselName }
      ]);
      setInitialized(true);
    } else {
      content = (
        <div className="h-screen flex flex-col items-center justify-center">
          <ErrorMessage />
        </div>
      );
      setInitialized(true);
    }
  }

  return (
    <SelectedVesselsContext.Provider
      value={{ selectedVessels, setSelectedVessels }}
    >
      {content}
    </SelectedVesselsContext.Provider>
  );
};
