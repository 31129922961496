import { useLocalStorageBackedState } from './useLocalStorageBackedState';
import { Vessel } from '../models/Vessel';
import { useState } from 'react';
import { useRouter } from 'next/router';
import { HOME_PAGE_URL } from './routes';
import { FILTER_STORAGE_KEY } from './localStorageKeys';

export type FilterBy = keyof Pick<
  Vessel,
  | 'VesselType'
  | 'Fleet'
  | 'StatusDetail'
  | 'VesselName'
  | 'CycloneProximityFlag'
  | 'VesselClass'
  | 'Active'
  | 'Team'
  | 'Coast'
>;

export type BooleanFilterBy = keyof Pick<Vessel, 'Active'>;

export type TextFilterBy = keyof Pick<
  Vessel,
  | 'VesselType'
  | 'Fleet'
  | 'StatusDetail'
  | 'VesselName'
  | 'CycloneProximityFlag'
  | 'VesselClass'
  | 'Coast'
>;

export type ToggleFilters = Partial<Record<FilterBy, Set<string>>>;

export type TextFilters = Partial<Record<TextFilterBy, string>>;

export type FilterChangeTextSingleValueFn = (
  filterType: TextFilterBy,
  filterValue: string
) => void;

export type FilterChangeSingleValueFn = (
  filterType: FilterBy,
  filterValue: string
) => void;

export type FilterChangeSetFn = (
  filterType: FilterBy,
  filterValue: Set<string>
) => void;

interface OptionsProps {
  initialToggleFilterState?: ToggleFilters;
  initialTextFilterState?: TextFilters;
  persist?: boolean;
}

export function useFilterState({
  initialToggleFilterState = {},
  initialTextFilterState = {},
  persist = true
}: OptionsProps = {}) {
  const { query, push } = useRouter();
  const [activeToggleFilters, setActiveToggleFilters] =
    useLocalStorageBackedState<ToggleFilters>({
      key: FILTER_STORAGE_KEY,
      initialValue: initialToggleFilterState,
      persist
    });

  const [activeTextFilters, setActiveTextFilters] = useState<TextFilters>(
    initialTextFilterState
  );
  /**
   *  This function is used to change a single value in filter type
   */
  const onFilterChangeSingleValue: FilterChangeSingleValueFn = (
    filterType,
    filterValue
  ) => {
    // Create a new set from existing values for the type
    const newValue = new Set(activeToggleFilters[filterType]);

    // If the filter is already selected, unselect it or add it if not selected
    newValue.has(filterValue)
      ? newValue.delete(filterValue)
      : newValue.add(filterValue);

    // Call the fn to change a set when the set is created
    onFilterChangeSet(filterType, newValue);
  };

  /*
   * This function takes in a set of values and updates the state for the filter type
   */
  const onFilterChangeSet: FilterChangeSetFn = (filterType, filterValue) => {
    setActiveToggleFilters((prev) => {
      // If the set has values add it to state
      if (filterValue.size !== 0) {
        return {
          ...prev,
          [filterType]: filterValue
        };
      }

      // Remove any empty keys so the state is always clean and don't have `type: {}`
      const { [filterType]: removedKey, ...remainingActiveToggleFilters } =
        prev;

      return {
        ...remainingActiveToggleFilters
      };
    });
  };

  const onTextFilterChangeValue: FilterChangeTextSingleValueFn = (
    filterType,
    filterValue
  ) => {
    // If the user is on the summary page when filtering by vessel name, return it to the list view
    if (filterType === 'VesselName' && query.summary) {
      push(HOME_PAGE_URL, undefined, { shallow: true });
    }
    setActiveTextFilters((prev) => {
      if (filterValue && filterValue.length > 0) {
        return {
          ...prev,
          [filterType]: filterValue
        };
      }

      const { [filterType]: removedKey, ...remainingActiveTextFilters } = prev;

      return {
        ...remainingActiveTextFilters
      };
    });
  };

  const resetAllFilters = () => setActiveToggleFilters({});

  return {
    activeToggleFilters,
    setActiveToggleFilters,
    activeTextFilters,
    onFilterChangeSet,
    onFilterChangeSingleValue,
    onTextFilterChangeValue,
    resetAllFilters
  };
}
