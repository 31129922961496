import { isNaN, isNil, mapValues } from 'lodash';
import { VesselStatic } from '../models/VesselStatic';
import { DateTime } from 'luxon';

export type VesselDetailsValues = Pick<
  VesselStatic,
  | 'VesselOwner'
  | 'Ownership'
  | 'VesselCharterer'
  | 'VesselOperator'
  | 'VesselClass'
  | 'VesselGroup'
  | 'Fleet'
  | 'CARBCompliant'
  | 'CARBComplianceEndDate'
  | 'ValidSIREInspection'
  | 'LastSIREDate'
  | 'VesselType'
  | 'DeliveryDate'
  | 'NetTonnage'
  | 'VesselYard'
  | 'VesselHP'
  | 'VesselMinManRequirement'
  | 'UWILDApproved'
  | 'TypeOfLastDocking'
  | 'DateOfLastDocking'
  | 'TypeOfNextDocking'
  | 'DateOfNextDocking'
> &
  Partial<VesselStatic>;

export interface VesselDetailsForm {
  VesselOwner: string;
  Ownership: string;
  VesselCharterer: string;
  VesselOperator: string;
  VesselClass: string;
  VesselGroup: string;
  Fleet: string;
  CARBCompliant: string;
  CARBComplianceEndDate: string;
  ValidSIREInspection: string;
  LastSIREDate: string;
  VesselType: string;
  DeliveryDate: string;
  NetTonnage: string;
  VesselYard: string;
  VesselHP: string;
  VesselMinManRequirement: string;
  UWILDApproved: string | boolean;
  TypeOfLastDocking: string;
  DateOfLastDocking: string;
  TypeOfNextDocking: string;
  DateOfNextDocking: string;
}

export type TypeSpecificValues = Pick<
  VesselStatic,
  | 'ChemicalRated'
  | 'IMOType'
  | 'NumberOfSegments'
  | 'Nitrogen'
  | 'Cleaning'
  | 'TankCoatingType'
  | 'BWTSInstalled'
  | 'BWTSInstallationDueDate'
  | 'VesselCapacity'
  | 'ABSClassID'
  | 'PortOfRegistry'
  | 'ATBIMONumber'
  | 'BargeOfficialNumber'
  | 'TEU'
> &
  Partial<VesselStatic>;

export interface TypeSpecificForm {
  ChemicalRated?: string;
  IMOType?: string;
  NumberOfSegments?: string;
  Nitrogen?: string;
  Cleaning?: string;
  TankCoatingType?: string;
  BWTSInstalled?: string;
  BWTSInstallationDueDate?: string;
  VesselCapacity?: string;
  ABSClassID?: string;
  PortOfRegistry?: string;
  ATBIMONumber?: string;
  BargeOfficialNumber?: string;
  TEU?: string;
}

export const getVesselUrl = () => {
  return `${process.env.NEXT_PUBLIC_API_BASE}/v2/vessels`;
};

export const formatNumber = (value: string | undefined) => {
  let returnValue;
  if (value === '' || isNil(value)) returnValue = NaN;
  else returnValue = Number(value);

  return returnValue;
};

export const formatVesselDetailFormIntoModel = (
  formValues: VesselDetailsForm,
  vesselStatic: VesselStatic
): VesselStatic => {
  const values: VesselDetailsValues = {
    ...formValues,
    CARBComplianceEndDate: new Date(formValues.CARBComplianceEndDate).getTime(),
    LastSIREDate: new Date(formValues.LastSIREDate).getTime(),
    DeliveryDate: new Date(formValues.DeliveryDate).getTime(),
    NetTonnage: formatNumber(formValues.NetTonnage),
    VesselHP: formatNumber(formValues.VesselHP),
    VesselMinManRequirement: formatNumber(formValues.VesselMinManRequirement),
    DateOfLastDocking: new Date(formValues.DateOfLastDocking).getTime(),
    DateOfNextDocking: new Date(formValues.DateOfNextDocking).getTime(),
    UWILDApproved: !isNil(formValues.UWILDApproved)
      ? formValues.UWILDApproved === 'true' || formValues.UWILDApproved === true
      : undefined
  };

  const formattedValues: VesselStatic = {
    ...vesselStatic,
    ...mapValues(values, (v: typeof values) =>
      isNaN(v) || (!v && v !== 0 && v !== false) ? null : v
    )
  };

  return formattedValues;
};

export const formatDateTime = (
  value: number | undefined | null
): string | undefined => {
  return value ? DateTime.fromMillis(value).toFormat('yyyy-MM-dd') : undefined;
};

export const formatVesselDetailModelIntoForm = (
  vesselStaticValues: VesselDetailsValues | null | undefined
): Partial<VesselDetailsForm> => {
  if (!vesselStaticValues) {
    return {};
  }

  const {
    CARBComplianceEndDate,
    LastSIREDate,
    DeliveryDate,
    NetTonnage,
    VesselHP,
    VesselMinManRequirement,
    DateOfLastDocking,
    DateOfNextDocking
  } = vesselStaticValues;

  const values: Partial<VesselDetailsForm> = {
    ...vesselStaticValues,
    CARBComplianceEndDate: formatDateTime(CARBComplianceEndDate),
    LastSIREDate: formatDateTime(LastSIREDate),
    DeliveryDate: formatDateTime(DeliveryDate),
    NetTonnage: NetTonnage?.toString(),
    VesselHP: VesselHP?.toString(),
    VesselMinManRequirement: VesselMinManRequirement?.toString(),
    DateOfLastDocking: formatDateTime(DateOfLastDocking),
    DateOfNextDocking: formatDateTime(DateOfNextDocking)
  };

  return values;
};

export const formatDateToMillis = (value: string | undefined) => {
  return new Date(value || '').getTime();
};

export const formatTypeSpecificFormIntoModel = (
  formValues: TypeSpecificForm,
  vesselStatic: VesselStatic
): VesselStatic => {
  const values: TypeSpecificValues = {
    ...formValues,
    BWTSInstallationDueDate: formatDateToMillis(
      formValues.BWTSInstallationDueDate
    ),
    NumberOfSegments: formatNumber(formValues.NumberOfSegments),
    VesselCapacity: formatNumber(formValues.VesselCapacity),
    ATBIMONumber: formatNumber(formValues.ATBIMONumber),
    TEU: formatNumber(formValues.TEU)
  };

  const formattedValues: VesselStatic = {
    ...vesselStatic,
    ...mapValues(values, (v: typeof values) =>
      isNaN(v) || (!v && v !== 0 && v !== false) ? null : v
    )
  };

  return formattedValues;
};

export const formatTypeSpecificModelIntoForm = (
  vesselStatic: TypeSpecificValues | null | undefined
): TypeSpecificForm => {
  const values: TypeSpecificForm = {
    ...vesselStatic,
    BWTSInstallationDueDate: formatDateTime(
      vesselStatic?.BWTSInstallationDueDate
    ),
    NumberOfSegments: vesselStatic?.NumberOfSegments?.toString(),
    VesselCapacity: vesselStatic?.VesselCapacity?.toString(),
    ATBIMONumber: vesselStatic?.ATBIMONumber?.toString(),
    TEU: vesselStatic?.TEU?.toString()
  };

  return values;
};
