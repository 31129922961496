import { Layout } from '@org-crowley/enterprise-react-component-library';
import { OktaProvider } from '../contexts/OktaContext';
import { AppProps } from 'next/app';
import Head from 'next/head';
import { FilterProvider } from '../contexts/FilterContext';
import '../components/tailwind.css';
import { VesselProvider } from '../contexts/VesselContext';
import { useRouter } from 'next/router';
import { MapProvider } from 'react-map-gl';
import { useState } from 'react';
import { FeatureFlagProvider } from '../contexts/FeatureFlagContext';
import { useDataDog } from '../utils/useDataDog';
import { HOME_PAGE_URL } from '../utils/routes';
import { DefaultRedirectProvider } from '../contexts/DefaultRedirectContext';
import { SelectedVesselsProvider } from '../contexts/SelectedVesselsContext';

function CustomApp({ Component, pageProps }: AppProps) {
  useDataDog();
  const { push } = useRouter();
  const [userName, setUserName] = useState('');
  const [mastHeadURL, setMastHeadURL] = useState<string>(HOME_PAGE_URL);

  return (
    <>
      <Head>
        <title>Vessel Management</title>
      </Head>
      <OktaProvider setUserName={setUserName} userName={userName}>
        <FeatureFlagProvider>
          <FilterProvider>
            <VesselProvider>
              <SelectedVesselsProvider>
                <DefaultRedirectProvider
                  mastHeadURL={mastHeadURL}
                  setMastHeadURL={setMastHeadURL}
                >
                  <MapProvider>
                    <Layout
                      masthead={{
                        title: '',
                        logo: '/cvoy-logo.svg',
                        onLogoClick: () => push(mastHeadURL),
                        name: userName
                      }}
                    >
                      <Component {...pageProps} />
                    </Layout>
                  </MapProvider>
                </DefaultRedirectProvider>
              </SelectedVesselsProvider>
            </VesselProvider>
          </FilterProvider>
        </FeatureFlagProvider>
      </OktaProvider>
    </>
  );
}

export default CustomApp;
