export const HOME_PAGE_URL = '/';
export const LOGIN_PAGE_URL = '/login';
export const DETAILS_PAGE_URL = '/details';
export const LIST_PAGE_URL = '/list';
export const NOT_FOUND_PAGE_URL = '/404';
export const CARBON_INTENSITY_INDICATOR_URL = '/cii';
export const WELCOME_URL = '/welcome';
export const REGISTRY_URL = '/registry';
export const USER_MANAGEMENT_URL = '/users';
export const CALENDAR_URL = '/calendar';
