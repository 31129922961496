import { createContext, ReactNode } from 'react';
import {
  FilterChangeSetFn,
  FilterChangeSingleValueFn,
  ToggleFilters,
  TextFilters,
  useFilterState,
  FilterChangeTextSingleValueFn
} from '../utils/useFilterState';

export interface FilterContextState {
  activeToggleFilters: ToggleFilters;
  activeTextFilters: TextFilters;
  onFilterChangeSingleValue: FilterChangeSingleValueFn;
  onFilterChangeSet: FilterChangeSetFn;
  onTextFilterChangeValue: FilterChangeTextSingleValueFn;
  resetAllFilters: () => void;
  applyMultipleToggleFilters: (toggleFilters: ToggleFilters) => void;
}

export const FilterContext = createContext<FilterContextState>(
  {} as FilterContextState
);

interface Props {
  children: ReactNode;
}

export function FilterProvider({ children }: Props) {
  const {
    activeToggleFilters,
    setActiveToggleFilters,
    activeTextFilters,
    resetAllFilters,
    onFilterChangeSet,
    onFilterChangeSingleValue,
    onTextFilterChangeValue
  } = useFilterState();

  const applyMultipleToggleFilters = (toggleFilters: ToggleFilters) =>
    setActiveToggleFilters(toggleFilters);

  return (
    <FilterContext.Provider
      value={{
        activeToggleFilters,
        activeTextFilters,
        onFilterChangeSet,
        onFilterChangeSingleValue,
        resetAllFilters,
        applyMultipleToggleFilters,
        onTextFilterChangeValue
      }}
    >
      {children}
    </FilterContext.Provider>
  );
}
