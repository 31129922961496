export const NOT_AUTHORIZED_ERROR =
  'You are not authorized to perform this action, please log in again';
export const TIMED_OUT_ERROR = 'Request timed out';
export const UNEXPECTED_ERROR = 'Unexpected error occurred';
export const REQUEST_ERROR = 'Error occurred sending request';
export const ASSET_NOT_FOUND = 'Asset not found';
export const NO_ASSET_INFO_ERROR = 'No asset information provided';
export const ASSET_EXISTS_ERROR = 'This asset already exists';
export const ASSET_NUMBER_ERROR = 'No asset number provided';
export const ASSET_TYPES_NOT_FOUND_ERROR = 'No asset types found';

export const NO_REGISTRY_INFO = 'No IMO/MMSI provided';

export const HARDWARE_INFORMATION_ERROR = 'No hardware information provided';
export const HARDWARE_EXISTS_ERROR = 'Hardware data already exists';
export const HARDWARE_NOT_FOUND_ERROR = 'Hardware data not found';

export const ROLE_ATTACHED_ERROR = 'Role is currently attached to users';
export const ROLE_NOT_FOUND_ERROR = 'Role data not found';
export const USER_NOT_FOUND_ERROR = 'User data not found';
export const USER_INFORMATION_ERROR = 'No user information provided';
export const USER_EXISTS_ERROR = 'User data already exists';
export const ROLE_INFORMATION_ERROR = 'No role information provided';
export const ROLE_EXISTS_ERROR = 'Role data already exists';

export const COMMUNICATIONS_INFORMATION_ERROR =
  'No communications information provided';
export const COMMUNICATIONS_EXISTS_ERROR = 'Communications data already exists';
export const COMMUNICATIONS_NOT_FOUND_ERROR = 'Communications data not found';

export const SOFTWARE_NOT_FOUND_ERROR = 'Software data not found';
export const SOFTWARE_INFORMATION_ERROR = 'No software information provided';
export const SOFTWARE_EXISTS_ERROR = 'Software data already exists';

export const EVENT_NOT_FOUND_ERROR = 'Events not found for vessel';
export const EVENT_NAMES_NOT_FOUND_ERROR = 'No names found';
export const CATEGORIES_NOT_FOUND_ERROR = 'No categories found';

export const COMMENT_NOT_FOUND_ERROR = 'Comments not found for vessel';
export const COMMENT_INFORMATION_ERROR = 'No comment information provided';
