import {
  Spinner,
  SpinnerSize,
  Typography,
  TypographyVariants
} from '@org-crowley/enterprise-react-component-library';

interface LoadingProps {
  spinnerSize?: SpinnerSize;
  text?: string;
  textVariant?: TypographyVariants;
  textAs?:
    | 'h5'
    | 'div'
    | 'h1'
    | 'h2'
    | 'h3'
    | 'h4'
    | 'h6'
    | 'p'
    | 'span'
    | undefined;
  textClassName?: string;
}

export function Loading({
  spinnerSize = 'lg',
  text = 'One moment please...',
  textVariant = 'h500',
  textAs = 'h5',
  textClassName = 'mt-4'
}: LoadingProps) {
  return (
    <>
      <Spinner size={spinnerSize} />
      <Typography variant={textVariant} as={textAs} className={textClassName}>
        {text}
      </Typography>
    </>
  );
}
