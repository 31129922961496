import { createContext, ReactNode, useState } from 'react';

export interface FeatureFlagContextState {
  featureCyclonesEnabled: boolean;
  featureCiiEnabled: boolean;
  featureEditEventsEnabled: boolean;
  featureNewEventsEnabled: boolean;
  featureDeleteEventsEnabled: boolean;
  featureCalendarZoomEnabled: boolean;
  featureCalendarVoyageEnabled: boolean;
  featureOneTimeEventsEnabled: boolean;
}

export const FeatureFlagContext = createContext<FeatureFlagContextState>(
  {} as FeatureFlagContextState
);

interface Props {
  children: ReactNode;
}

export const FeatureFlagProvider = ({ children }: Props) => {
  const [featureCyclonesEnabled] = useState<boolean>(
    process.env.NEXT_PUBLIC_FEATURE_CYCLONES_ENABLED === 'true'
  );
  const [featureCiiEnabled] = useState<boolean>(
    process.env.NEXT_PUBLIC_FEATURE_CII_ENABLED === 'true'
  );

  const [featureEditEventsEnabled] = useState<boolean>(
    process.env.NEXT_PUBLIC_FEATURE_EDIT_EVENTS_ENABLED === 'true'
  );

  const [featureNewEventsEnabled] = useState<boolean>(
    process.env.NEXT_PUBLIC_FEATURE_NEW_EVENTS_ENABLED === 'true'
  );

  const [featureDeleteEventsEnabled] = useState<boolean>(
    process.env.NEXT_PUBLIC_FEATURE_DELETE_EVENTS_ENABLED === 'true'
  );

  const [featureCalendarZoomEnabled] = useState<boolean>(
    process.env.NEXT_PUBLIC_FEATURE_CALENDAR_ZOOM_ENABLED === 'true'
  );

  const [featureCalendarVoyageEnabled] = useState<boolean>(
    process.env.NEXT_PUBLIC_FEATURE_CALENDAR_VOYAGE_ENABLED === 'true'
  );

  const [featureOneTimeEventsEnabled] = useState<boolean>(
    process.env.NEXT_PUBLIC_FEATURE_ONE_TIME_EVENTS_ENABLED === 'true'
  );

  return (
    <FeatureFlagContext.Provider
      value={{
        featureCyclonesEnabled,
        featureCiiEnabled,
        featureEditEventsEnabled,
        featureNewEventsEnabled,
        featureDeleteEventsEnabled,
        featureCalendarZoomEnabled,
        featureCalendarVoyageEnabled,
        featureOneTimeEventsEnabled
      }}
    >
      {children}
    </FeatureFlagContext.Provider>
  );
};
