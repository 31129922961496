import axios from 'axios';
import useSWR from 'swr';
import { User } from '../models/User';
import {
  NOT_AUTHORIZED_ERROR,
  REQUEST_ERROR,
  TIMED_OUT_ERROR,
  UNEXPECTED_ERROR,
  USER_NOT_FOUND_ERROR
} from './apiConstants';

export interface UseUserProps {
  emailAddress: string | undefined;
  accessToken: string | undefined;
  shouldFetchUser: boolean | undefined;
}

interface APIResponse {
  success: string;
  message?: string;
  error?: Error;
  data: User[];
}

const userFetcher = (url: string, token: string) =>
  axios
    .get<APIResponse>(url, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
    .then(({ data }) => data.data[0])
    .catch((error) => {
      if (error.response) {
        switch (error.response.status) {
          case 401: {
            throw new Error(NOT_AUTHORIZED_ERROR);
          }
          case 404: {
            throw new Error(USER_NOT_FOUND_ERROR);
          }
          case 503: {
            throw new Error(TIMED_OUT_ERROR);
          }
          default: {
            throw new Error(UNEXPECTED_ERROR);
          }
        }
      } else if (error.request) {
        throw new Error(REQUEST_ERROR);
      } else {
        throw new Error(UNEXPECTED_ERROR);
      }
    });

export function useUser({
  emailAddress,
  accessToken,
  shouldFetchUser
}: UseUserProps) {
  const {
    data: userData,
    error: userError,
    isLoading: isLoadingUser
  } = useSWR(
    () => {
      if (emailAddress && shouldFetchUser) {
        return `${
          process.env.NEXT_PUBLIC_API_BASE
        }/v2/users?email=${emailAddress.toLowerCase()}`;
      }
      return null;
    },
    (key) => {
      if (emailAddress && accessToken && shouldFetchUser) {
        return userFetcher(key, accessToken);
      }
      return null;
    }
  );
  return {
    userData,
    userError,
    isLoadingUser
  };
}
